import React from "react";
import { Dialog } from 'primereact/dialog';
import moment from 'moment';

const ShowMeetingNotes = ({item, openViewNotes, closeViewNotes}) => {

    const formatDate = (dateStr) => {
        if (!dateStr) return 'N/A';
        return moment(dateStr).format('DD-MM-YYYY hh:mm a');
    };

    return (
        <Dialog
            header={`${item.name}  Meeting Notes`}
            visible={openViewNotes}
            maximizable
            style={{ width: '50vw' }}
            onHide={closeViewNotes}
        >
            <div className="notes-history mt-3">
                {item?.meetingNotes?.length > 0 ? (
                    item?.meetingNotes?.map((note, index) => (
                        <div key={index} className="notes-card mb-3">
                            <span className="ms-3">{note.meetingDate}</span>
                            <div className="card-body d-flex justify-content-between">
                                <div className="me-5 flex-grow-1">
                                    <h6 className="card-ti" dangerouslySetInnerHTML={{ __html: note.projectNotes }} />
                                    <div className="mt-3">
                                        <small className="row text-muted show-employees">
                                            <span className="col-4">
                                                <b>Attended Employees:</b>
                                            </span>
                                            <span className="col-8">
                                                {note.attendedEmployees && note.attendedEmployees.length > 0 ?
                                                    note.attendedEmployees.map(item => item.displayName).join(', ')
                                                    : 'None'}
                                            </span>
                                            <span className="col-4">
                                                <b>Task Id's:</b>
                                            </span>
                                            <span className="col-8 text-success">
                                                {note.taskIDs && note.taskIDs.length > 0 ?
                                                    note.taskIDs.join(', ')
                                                    : 'None'}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-end">
                                    <div className="date-time">
                                        <small className="text-muted"><b>Created:</b> {formatDate(note.created)}</small>
                                    </div>
                                    <div className="date-time">
                                        <small className="text-muted">
                                            <b>Status: </b>
                                            <span style={{ color: note.status === "In Discussion" ? "coral" : "#0000FF" }}>
                                                {note.status ? note.status : "None"}
                                            </span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="no-notes">
                        <p>No Notes Availabale</p>
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default ShowMeetingNotes;
