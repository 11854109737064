import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const ApproveOrRejectForMultiselect = (props) => {

    const [rejectReason, setRejectReason] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    let closeRejectModal = () => {
        props.setIsOpenModal(false)
    }

    let handleInputChange = (event) => {
        setRejectReason(event.target.value);
    };

    let closeConfirmationModal = () => {
        props.setIsOpenModal(false)
    }

    let saveDataToServer = (type, reason) => {
        props.saveDataToServer(props.item, props.field, type, reason)
    }

    let getApproveModal = () => {
        return (
            <Modal isOpen
                className={`modal-dialog-centered`} style={{ maxWidth: 500 }}>
                <ModalBody>
                    <div style={{ marginBottom: 20 }}>{'Are you sure want to Approve ?'}</div>
                    <Button color="primary" outline onClick={closeConfirmationModal} className='deleteModalBtn marginRight'>{'No'}</Button>
                    <Button color="primary" outline onClick={() => saveDataToServer('Approved')} disabled={isSubmitting} 
                        className='deleteModalBtn'>{'Yes'}</Button>
                </ModalBody>
            </Modal >
        )
    }

    let getRejectModal = () => {
        return (
            <Modal isOpen className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `} style={{ maxWidth: "35%" }}>
                <ModalHeader className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={() => closeRejectModal()} />
                    <p className="bold-text modal__title"> {'Reason'} </p>
                </ModalHeader>
                <ModalBody >
                    <div className='form__form-group' style={{ display: "flex", margin: "20px" }}>
                        <span className="form__form-group-label" style={{ margin: '50px' }}>{'Reason'}</span>
                        <div className="form__form-group-field">
                            <textarea
                                name='rejectReason'
                                id='reason'
                                placeholder='RejectReason'
                                value={rejectReason}
                                onChange={handleInputChange}
                                style={{ width: '60%', height: '100px' }}
                            />
                        </div>
                    </div>
                    <div className='text-center mt-3'>
                        <Button color='primary' outline onClick={closeRejectModal}>{'Close'}</Button>
                        <Button color='primary' outline onClick={() => saveDataToServer('Rejected', rejectReason)}>{'Save'}</Button>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    return (
        <>
            {props?.value === "Approved" ? getApproveModal() : getRejectModal()}
        </>
    );

}
export default ApproveOrRejectForMultiselect;