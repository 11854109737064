import React, { useEffect } from "react";
import classNames from 'classnames';
import { Button, Modal, ModalHeader, ModalBody, ButtonToolbar, Card, CardBody } from 'reactstrap';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// config file
import InputField from "../../../Sample/InputField";
import DoTextareaField from "../../../Sample/DoTextareaField";
import DoSelectField from "../../../Sample/DoSelectField";
import { Controller, useForm } from "react-hook-form";
import fetchMethodRequest from "../../../config/service";

const LeaveBalanceModal = (props) => {

    let options = [
        { label: 'Add', value: 'Add' },
        { label: 'Subtract', value: 'Subtract' },
    ];

    useEffect(() => {
        const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
        if (modal) {
            modal.style.zIndex = '300';
        }
    }, [])

    const { handleSubmit, reset, control, } = useForm();

    let submit = (value) => {
        sendDataToServer(value);
    }

    let sendDataToServer = async (value) => {
        let url = `employees/${props.leaveData._id}?type=LeaveUpdate`;
        let body = {
            numberOfDays: parseFloat(value.noOfDays),
            reason: value.rejectReason,
            operation: value.leaveOptions
        }
        return fetchMethodRequest('PUT', url, body)
            .then(async (response) => {
                props.closeLeaveUpdateModal();
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');

                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                props.getDataFromServer();
                reset();
            }).catch((err) => {
                return err;
            });
    }

    const modalClass = classNames({
        'modal-dialog--colored': false,
        'modal-dialog--header': true,
    });
    return (
        <Modal isOpen={props.isOpenLeaveBalanceModal} className={`leave-modal modal-dialog-centered modal-dialog--primary ${modalClass}`}>
            <ModalHeader className="modal__header">
                <button className="lnr lnr-cross modal__close-btn" type="button" onClick={props.closeLeaveUpdateModal} />
                <h4>{'Update Leave Balance'}</h4>
            </ModalHeader>
            <div className="custom-leave-modal">
                <Card>
                    <CardBody className='cardHeader'>
                        <form className="form form--horizontal" onSubmit={handleSubmit(submit)}>
                            <div className="form__form-group">
                                <label className="form__form-group-label">{'Reason'}</label>
                                <div className="form__form-group-field">
                                    <Controller
                                        name="rejectReason"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <DoTextareaField
                                                input={field}
                                                id={field.id}
                                                name={field.name}
                                                field={field}
                                                fieldState={fieldState}
                                                placeholder="Reason..."
                                            />)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <label className="form__form-group-label">{'No Of Days'}</label>
                                <div className="form__form-group-field">
                                    <Controller
                                        name="noOfDays"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <InputField
                                                input={field}
                                                id={field.id}
                                                name={field.name}
                                                field={field}
                                                type="text"
                                                fieldState={fieldState}
                                                placeholder="No of days"
                                            />)}
                                    />
                                </div>
                            </div>
                            <div className="form__form-group">
                                <label className="form__form-group-label">{'Leave Options'}</label>
                                <Controller
                                    name="leaveOptions"
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <DoSelectField
                                            input={field}
                                            id={field.id}
                                            name={field.name}
                                            field={field}
                                            fieldState={fieldState}
                                            options={options}
                                            placeholder="Select an option"
                                        />)}
                                />
                            </div>
                            <div className="col-sm-12">
                                <ButtonToolbar className="modal__footer footerData">
                                    <Button color="primary" outline onClick={props.closeLeaveUpdateModal}>
                                        {'Cancel'}
                                    </Button>
                                    <Button color="primary" type="submit" outline>
                                        {'Update'}
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </form>
                    </CardBody>
                </Card >
            </div>
        </Modal >
    );
}
export default LeaveBalanceModal;